import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import noImage from '../assets/no_image.jpg';

const styles = theme => ({
  card: {
    borderRadius: 0,
  },
  media: {
    height: 0,
    paddingTop: '95%', // 56.25% = 16:9
  },
  title: {
    fontFamily: 'Special Elite',
    marginTop: 7,
    lineHeight: '1.2',
  },
  link: {
    textDecoration: 'none',
  },
});

class FeedItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    const { classes, data } = this.props;

    return (
      <Grid item xs={12} sm={4} lg={4} xl={3}>
        <Card className={classes.card}>
          <CardActionArea component={Link} to={`/cocktail/${data.id}`}>
            <CardMedia
              className={classes.media}
              image={data.image.large ? data.image.large : noImage}
              title={data.title}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                className={classes.title}
                align="center"
                variant="subtitle1"
              >
                {data.title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }
}

FeedItem.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedItem);
