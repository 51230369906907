import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SearchBar from 'material-ui-search-bar';
import { Route, Link, withRouter } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '../components/CircularProgress';
import SingleCocktail from '../components/SingleCocktail';
import Feed from '../components/Feed';

const apiUrl = 'https://api.äpplena.se/wp-json/wp/v2/cocktails?per_page=100';

const config = {
  searchableFields: ['title', 'background'],
  sortings: {},
  aggregations: {},
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 1170,
      margin: '0 auto',
    },
  },
  header: {
    padding: theme.spacing.unit * 2,
    paddingBottom: 0,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 3,
    },
  },
  logo: {
    marginTop: 16,
    textDecoration: 'none',
    color: theme.palette.common.black,
    fontFamily: 'Special Elite',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  emptyResult: {
    marginTop: 30,
    color: fade(theme.palette.common.black, 0.7),
  },
});

const HomeLink = props => <Link to="/" {...props} />;

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      filteredItems: [],
    };
  }

  componentDidMount() {
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        result => {
          result = this.shuffleArray(result);

          this.setState({
            isLoaded: true,
            items: result,
            filteredItems: result,
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  shuffleArray = arr => arr.sort(() => Math.random() - 0.5);

  onSearchChange = value => {
    const { history } = this.props;
    const { location } = history;
    const { items } = this.state;
    const itemsjs = require('itemsjs')(items, config);
    const result = itemsjs.search({
      query: value,
    });

    if (location.pathname !== '/') {
      history.push('/');
    }

    this.setState({
      filteredItems: result.data.items,
    });
  };

  resetItems = () => {
    const { items } = this.state;
    this.setState({
      filteredItems: items,
    });
  };

  render() {
    const { classes } = this.props;
    const { error, isLoaded, filteredItems } = this.state;

    return (
      <div className={classes.root}>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Grid
            container
            className={classes.header}
            spacing={24}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12} md={6} sm={7}>
              <Typography
                className={classes.logo}
                component={HomeLink}
                variant="h5"
                color="inherit"
                noWrap
              >
                SÄLLSKAPET ÄPPLENA
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sm={5}>
              <SearchBar
                placeholder="Sök"
                onChange={this.onSearchChange}
                onCancelSearch={this.resetItems}
                cancelOnEscape
              />
            </Grid>
          </Grid>
        </div>

        {error && (
          <Typography variant="body1" align="center">
            Error: {error.message}
          </Typography>
        )}

        {!isLoaded && <CircularProgress />}

        {isLoaded && filteredItems.length === 0 && (
          <Typography className={classes.emptyResult} align="center">
            Din sökning gav inget resultat.
          </Typography>
        )}

        <Route
          path="/"
          exact
          render={props => <Feed {...props} items={filteredItems} />}
        />
        <Route
          path="/cocktail/:id"
          render={props => <SingleCocktail {...props} items={filteredItems} />}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Page));
