import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import FeedItem from './FeedItem';

const styles = theme => ({
  grid: {
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 3,
    },
  },
});

function Feed(props) {
  const { classes } = props;

  return (
    <div>
      <Fade in>
        {/* hack to fix bug in grid spacing */}
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Grid container className={classes.grid} spacing={32}>
            {props.items.map(item => (
              <FeedItem
                className={classes.feedItem}
                key={item.id}
                data={item}
              />
            ))}
          </Grid>
        </div>
      </Fade>
    </div>
  );
}

export default withStyles(styles)(Feed);
