import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Avatar from '@material-ui/core/Avatar';
import noImage from '../assets/no_image.jpg';
import CircularProgress from './CircularProgress';

const styles = theme => ({
  avatar: {
    marginLeft: 10,
    float: 'right',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    borderRadius: 2,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 3,
    },
  },
  title: {
    fontFamily: 'Special Elite',
  },
  subHeader: {
    fontFamily: 'Special Elite',
  },
  grid: {
    padding: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing.unit * 5,
    },
  },
  media: {
    borderRadius: 0,
    width: '100%',
  },
  volume: {
    paddingLeft: 8,
    color: fade(theme.palette.common.black, 0.7),
  },
  table: {
    borderLeft: '2px solid #ddd',
  },
  td: {
    paddingRight: 8,
  },
  backButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

const HomeLink = props => <Link to="/" {...props} />;

class SingleCocktail extends Component {
  render() {
    const { id } = this.props.match.params;
    const { classes, items } = this.props;
    const item = items.find(obj => parseInt(obj.id) === parseInt(id));

    if (item) {
      return (
        <Grid container className={classes.grid} justify="center">
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper} elevation={1}>
              <Grid container spacing={32}>
                {item.image.large && (
                  <Grid item xs={12} sm={5} md={5}>
                    <img
                      src={item.image.large ? item.image.large : noImage}
                      className={classes.media}
                      alt={item.title}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={7} md={7}>
                  <Grid container spacing={24}>
                    <Grid item xs={10} md={10}>
                      <Typography
                        className={classes.title}
                        variant="h4"
                        align="left"
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      {item.average_rating && (
                        <Avatar className={classes.avatar}>
                          {item.average_rating}
                        </Avatar>
                      )}
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <table className={classes.table}>
                        <tbody>
                          {item.ingredients.map((item, i) => (
                            <tr key={i}>
                              <td className={classes.td}>
                                <Typography className={classes.volume}>
                                  {item.volume}
                                </Typography>
                              </td>
                              <td className={classes.td}>
                                <Typography className={classes.ingredient}>
                                  {item.ingredient.name}
                                </Typography>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {item.preparations && (
                        <div>
                          <Typography
                            variant="h6"
                            className={classes.subHeader}
                          >
                            Förberedelser
                          </Typography>
                          <Typography
                            paragraph
                            dangerouslySetInnerHTML={{
                              __html: item.preparations,
                            }}
                          />
                        </div>
                      )}

                      <Typography variant="h6" className={classes.subHeader}>
                        Utförande
                      </Typography>
                      <Typography
                        paragraph
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />

                      {item.background && (
                        <div>
                          <Typography
                            variant="h6"
                            className={classes.subHeader}
                          >
                            Bakgrund
                          </Typography>
                          <Typography
                            paragraph
                            dangerouslySetInnerHTML={{
                              __html: item.background,
                            }}
                          />
                        </div>
                      )}

                      {item.garnish && (
                        <Typography variant="h6" className={classes.subHeader}>
                          Garnityr
                        </Typography>
                      )}
                      {item.garnish ? (
                        <Typography paragraph>{item.garnish}</Typography>
                      ) : (
                        ''
                      )}

                      {item.glass_types.name ? (
                        <Typography variant="h6" className={classes.subHeader}>
                          Glas
                        </Typography>
                      ) : (
                        ''
                      )}
                      {item.glass_types.name ? (
                        <Typography paragraph>
                          {item.glass_types.name}
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Button
              component={HomeLink}
              className={classes.backButton}
              size="small"
            >
              <ArrowBack />
              Tillbaka
            </Button>
          </Grid>
        </Grid>
      );
    }
    return '';
  }
}

export default withStyles(styles)(SingleCocktail);
