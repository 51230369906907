import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createBrowserHistory } from 'history';

import ScrollToTop from './components/ScrollToTop';
import Page from './containers/Page';
import './style/main.css';

import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: cyan,
    secondary: cyan,
  },
});

ReactDOM.render(
  <Router>
    <MuiThemeProvider theme={theme}>
      <ScrollToTop>
        <Page history={history} />
      </ScrollToTop>
    </MuiThemeProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
